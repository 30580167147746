<template>
  <b-row>
    <b-col
      md="12"
    >
      <b-card>
        <h2>Informazioni Interazione</h2>
        <b-row>
          <b-col
            cols="12"
            md="8"
            xl="3"
          >
            <b-form-group>
              <h5>Data</h5>
              <b-form-datepicker
                id="example-datepicker"
                v-model="datePicker"
                class="mb-1"
                v-bind="labelsDate[locale] || {}"
                :locale="locale"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            xl="3"
          >
            <b-form-group>
              <h5>Orario</h5>
              <b-form-timepicker
                v-model="timePicker"
                v-bind="labelsTime[locale] || {}"
                :locale="locale"
              />

            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="3"
          >
            <b-form-group>
              <h5>Interazione</h5>
              <v-select
                v-model="interactionSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionInteractionFrom"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            xl="3"
          >
            <b-form-group>
              <h5>Motivo</h5>
              <v-select
                v-model="typeInteractionSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionTypeInteraction"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group>
              <h5>Descrizione</h5>
              <b-form-textarea
                id="textarea-default"
                v-model="description"
                placeholder="Textarea"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="6"
            lg="4"
          >
            <h5>Persona</h5>
            <b-form-group class="">
              <b-col
                class="d-flex m-0 p-0"
              >
                <vue-autosuggest
                  :suggestions="filteredOptions"
                  :limit="10"
                  :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Cerca Persona'}"
                  @selected="getSuggestionPerson"
                  @input="onInputChangePeople"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                  </template>
                </vue-autosuggest>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.quick-add-person-modal
                  variant="outline-primary"
                >
                  <feather-icon
                    icon="UserPlusIcon"
                  />
                </b-button>
                <QuickAddPerson @emitQuickAddPerson="pushQuickPerson" />
              </b-col>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="6"
            lg="4"
          >
            <b-form-group>
              <h5>Paziente</h5>
              <vue-autosuggest
                :suggestions="filteredOptions"
                :limit="10"
                :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Cerca Paziente'}"
                @selected="getSuggestionPatient"
                @input="onInputChangePatient"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            md="6"
            lg="4"
          >
            <b-form-group>
              <h5>Medico di turno</h5>
              <v-select
                v-model="selectedDoctor"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionDoctor"
              />
            </b-form-group>
          </b-col>
        </b-row>
        date {{ datePicker }}
        time {{ timePicker }}
        interaction {{ interactionSelected }}
        description {{ description }}
        typeInteractionSelected {{ typeInteractionSelected }}
        selectedPerson {{ selectedPerson }}
        selectedPatient {{ selectedPatient }}
        selectedDoctor {{ selectedDoctor }}
      </b-card>
    </b-col>
    <b-col
      md="12"
    >
      <b-card>
        <h2>Operazioni Contabili</h2>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <h5>Operazioni di cassa</h5>
              <v-select
                v-model="selectedCashOperations"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="cashOperationOption"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group>
              <h5>Dettaglio Operazione di cassa</h5>
              <v-select
                v-model="selectedDetailsCashOperation"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :disabled="selectedCashOperations.title === 'No cassa'"
                :options="detailsCashOperationOption"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="7"
            lg="4"
          >
            <b-form-group>
              <h5>Metodo di pagamento</h5>
              <v-select
                ref="method-p"
                v-model="selectedMethodPayment"
                :value="selectedMethodPayment"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :disabled="selectedCashOperations.title === 'No cassa'"
                :options="metodPaymentOptions"
              />
            </b-form-group>
          </b-col>
          <b-col class="d-flex justify-content-end p-0">
            <b-col
              cols="12"
              lg="3"
              xl="2"
            >
              <b-form-group>
                <h5>Importo Totale</h5>
                <b-form-input
                  id="inputImport"
                  v-model="totalToPay"
                  type="number"
                  :disabled="selectedCashOperations.title === 'No cassa'"
                  placeholder="Inserisci importo"
                />
              </b-form-group>
            </b-col>
          </b-col>
        </b-row>
        selectedMethodPayment {{ selectedMethodPayment }}
        selectedDetailsCashOperation {{ selectedDetailsCashOperation }}
        selectedCashOperations {{ selectedCashOperations }}
      </b-card>
    </b-col>
    <b-col class="d-flex justify-content-end">
      <button class="btn btn-primary">
        Registra
      </button>
    </b-col>
  </b-row>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormDatepicker, BFormTimepicker, BFormTextarea, BFormInput, VBModal, BButton, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import Ripple from 'vue-ripple-directive'
import QuickAddPerson from '@/views/pages/modal/QuickAddPerson.vue'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    VueAutosuggest,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    vSelect,
    BFormInput,
    QuickAddPerson,
  },
  data() {
    return {
      // label locale
      labelsDate: {
        it: {
          labelPrevDecade: 'Decennio Precedente',
          labelPrevYear: 'Anno Precendente',
          labelPrevMonth: 'Mese Precedente',
          labelCurrentMonth: 'Mese Attuale',
          labelNextMonth: 'Mese Successivo',
          labelNextYear: 'Anno Successivo',
          labelNextDecade: 'Decennio Successivo',
          labelToday: 'Oggi',
          labelSelected: 'Data Selezionata',
          labelNoDateSelected: 'Nessuna Data Selezionata',
          labelCalendar: 'Calendario',
          labelNav: 'Navigazione del calendario',
          labelHelp: 'Usa i tasti freccia per navigare nel calendario',
        },
      },
      labelsTime: {
        it: {
          labelHours: 'Ore',
          labelMinutes: 'Minuti',
          labelSeconds: 'Secondi',
          labelIncrement: 'Incrementa',
          labelDecrement: 'Decrementa',
          labelSelected: 'Selezionata',
          labelNoTimeSelected: 'Nessuna orario Selezionato',
          labelCloseButton: 'Chiudi',
        },
      },
      datePicker: null,
      timePicker: null,
      interactionSelected: { title: 'Telefonata' },
      optionInteractionFrom: [{ title: 'Telefonata' }, { title: 'In Sede' }, { title: 'Sito Web' }, { title: 'Referal' }, { title: 'Campagna Ads' }, { title: 'Social' }],
      description: '',
      typeInteractionSelected: { title: 'Prestazione' },
      optionTypeInteraction: [{ title: 'Prestazione' }, { title: 'Informazioni' }, { title: 'Appuntamento' }, { title: 'Fornitore' }, { title: 'Rappresentanza' }, { title: 'Altro...' }],

      // prova
      filteredOptions: [],

      // autosuggest persone
      peopleSuggest: [],
      peopleFiltered: [],
      limit: 10,
      selectedPerson: {},

      // autosuggest paziente
      selectedPatient: {},
      patientSuggest: [],
      patientFiltered: [],

      // doctor
      optionDoctor: [{ title: 'Pino', value: 'dott1' }, { title: 'Gino', value: 'dott2' }, { title: 'Pippo', value: 'dott3' }, { title: 'Tino', value: 'dott4' }],
      selectedDoctor: { title: '', value: '' },

      // operazioni di cassa
      cashOperationOption: [{ title: 'No cassa' }, { title: 'Entrata' }, { title: 'Uscita' }],
      selectedCashOperations: { title: 'No cassa' },

      // dettaglio operazione
      detailsCashOperationOption: [{ title: 'Versamento' }, { title: 'Sospseso di cassa' }, { title: 'Nota Rientro sospeso di cassa' }, { title: 'Prelievo' }],
      selectedDetailsCashOperation: { title: '' },

      // metodo di pagamento
      metodPaymentOptions: [{ title: 'Contanti' }, { title: 'Bancomant' }, { title: 'Carta di Credito' }, { title: 'Assegno' }, { title: 'Cambiali' }],
      selectedMethodPayment: { title: '' },
      //  importo da pagare
      totalToPay: 0,
      selectedMethodPayment1: '',
    }
  },
  computed: {
    // locale
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
  },
  mounted() {
  },
  created() {
    this.peopleSuggest = [
      {
        // developers
        developers: [
          {
            // img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
            name: 'Rena Brant',
            email: 'nephrod@preany.co.uk',
            time: '21/05/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
            name: 'Mariano Packard',
            email: 'seek@sparaxis.org',
            time: '14/01/2018',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
            name: 'Risa Montufar',
            email: 'vagary@unblist.org',
            time: '10/08/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
            name: 'Maragaret Cimo',
            email: 'designed@insanely.net',
            time: '01/12/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
            name: 'Jona Prattis',
            email: 'unwieldable@unblist.org',
            time: '21/05/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
            name: 'Edmond Chicon',
            email: 'museist@anaphyte.co.uk',
            time: '15/11/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
            name: 'Abbey Darden',
            email: 'astema@defectively.co.uk',
            time: '07/05/2019',
          },
        ],

        // designers
        designers: [
          {
            // img: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
            name: 'Seema Moallankamp',
            email: 'fernando@storkish.co.uk',
            time: '13/08/2017',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
            name: 'Charleen Warmington',
            email: 'furphy@cannibal.net',
            time: '11/08/1891',
          },
          {
            //  img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
            name: 'Geri Linch',
            email: 'insignia@markab.org',
            time: '18/01/2015',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
            name: 'Shellie Muster',
            email: 'maxillary@equalize.co.uk',
            time: '26/07/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
            name: 'Jesenia Vanbramer',
            email: 'hypotony@phonetist.net',
            time: '12/09/2017',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
            name: 'Mardell Channey',
            email: 'peseta@myrica.com',
            time: '11/11/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
            name: 'Lilliana Stroman',
            email: 'lilliana@stroman.com',
            time: '12/11/2020',
          },
        ],
      },
    ]
  },
  methods: {
    resetNoCash() {
      this.selectedMethodPayment.title = ''
    },
    pushQuickPerson(person) {
      this.peopleSuggest[0].developers.unshift(person)
    },
    getSuggestionPerson(text) {
      this.selectedPerson = text.item
    },
    getSuggestionPatient(text) {
      this.selectedPatient = text.item
    },
    onInputChangePeople(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.peopleSuggest[0].developers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredDesigner = this.peopleSuggest[0].designers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers.concat(filteredDesigner)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    onInputChangePatient(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.peopleSuggest[0].developers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredDesigner = this.peopleSuggest[0].designers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers.concat(filteredDesigner)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
